import React, { useContext } from 'react';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import styles from './index.module.scss';

import { UiKit } from 'components';
import { RetexContext } from 'components/RetexDetails';

export default function ServiceReason() {
  const { retex } = useContext(RetexContext);

  const reason = _get(retex, 'request_reason', '');
  const subReasonsData = _get(retex, 'request_sub_reasons_data', {});
  const subReasonTitle = _get(subReasonsData, 'title', '');
  const subReasons = _get(subReasonsData, 'sub_reasons', []);

  const hasSubReason = subReasons && !_isEmpty(subReasons);

  const subReasonAnswersList = subReasons.map((subReason, index) => {
    return (
      <li key={index} className={styles.value}>
        {subReason}
      </li>
    );
  });

  return (
    <UiKit.GridRow gap="20">
      <div className={styles.cell}>
        <span className={styles.label}>Return Reason</span>
        <p className={styles.value}>{reason}</p>
      </div>
      {hasSubReason && (
        <div className={styles.cell}>
          <span className={styles.label}>Sub-Reason</span>
          <span className={styles.helptext}>{subReasonTitle}</span>
          <ul className={styles.list}>{subReasonAnswersList}</ul>
        </div>
      )}
    </UiKit.GridRow>
  );
}
