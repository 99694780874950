import React, { useCallback, useContext, useState } from 'react';
import { Collapse } from 'reactstrap';
import { AddressEdit } from '@return-rabbit/nexus';
import _get from 'lodash/get';
import _isObject from 'lodash/isObject';
import styles from './index.module.scss';

import { UiKit } from 'components';
import { OrderServiceContext } from 'components/OrderServiceDetails';
import { post, get, patch, If } from 'utils';
import { useMutation } from 'utils/hooks';
import { ReactComponent as Arrow } from 'assets/img/icons/down-only.svg';
import comparePhoneNumbers from 'utils/ComparePhoneNumbers';
import Alert from '../Alert';

const addressKeyMapping = {
  firstName: 'first_name',
  lastName: 'last_name',
  address1: 'address1',
  address2: 'address2',
  country: 'country',
  zip: 'zip',
  city: 'city',
  province: 'province',
  phone: 'phone',
  email: 'email'
};

export default function RegenerateLabel() {
  const { orderService, updateOsKeyValue = () => {} } = useContext(
    OrderServiceContext
  );
  const [showModal, setShowModal] = useState(false);
  const [showFullError, setShowFullError] = useState(false);

  const osId = _get(orderService, 'id', '');
  const shippingInfo = _get(orderService, 'shipping_info', {});
  const shippingAddress = _get(orderService, 'shipping_address', {});
  const labelError = _get(shippingInfo, 'error', {});

  const onSuccessOrErrorRepair = useCallback(
    data => {
      if (_isObject(data)) {
        if (data.hasOwnProperty('shipping_info')) {
          updateOsKeyValue('shipping_info', data.shipping_info);
        }
        if (data.hasOwnProperty('shipping_address')) {
          updateOsKeyValue('shipping_address', data.shipping_address);
        }
      }
    },
    [updateOsKeyValue]
  );

  const {
    mutate: onRepairLabelGeneration,
    isProcessing: isProcessingRepair
  } = useMutation('requests.repairLabel', 'POST', {
    routeParams: { id: osId },
    payload: {
      reset_shipment_id: true
    },
    successMessage: `Successfully repaired and generated shipping label.`,
    errorMessage: 'Failed to repair label. Try again later.',
    onSuccess: onSuccessOrErrorRepair,
    // We are expecting the same response for both success and error (updated shipping data)
    onError: (_, data) => onSuccessOrErrorRepair(data)
  });

  const {
    mutate: onRetryLabelGeneration,
    isProcessing: isProcessingRetry
  } = useMutation('requests.repairLabel', 'POST', {
    routeParams: { id: osId },
    payload: {
      reset_shipment_id: false
    },
    successMessage: `Successfully generated shipping label.`,
    errorMessage: 'Failed to generate label. Try again later.',
    onSuccess: onSuccessOrErrorRepair,
    // We are expecting the same response for both success and error (updated shipping data)
    onError: (_, data) => onSuccessOrErrorRepair(data)
  });

  const onToggleModal = useCallback(() => setShowModal(show => !show), []);

  const compareAndRepairAddress = useCallback(
    address => {
      const isAddressSame = Object.entries(addressKeyMapping).every(
        ([key, value]) => {
          if (key === 'phone')
            return comparePhoneNumbers(shippingAddress.phone, address.phone);
          if (shippingAddress[value] === address[key]) return true;
          return false;
        }
      );
      if (isAddressSame) {
        onRepairLabelGeneration();
      } else {
        onRepairLabelGeneration({
          shipping_address: address
        });
      }
    },
    [shippingAddress, onRepairLabelGeneration]
  );

  if (!osId) return null;

  const address = {
    editing: true,
    data: {
      ...shippingAddress,
      firstName: shippingAddress.first_name,
      lastName: shippingAddress.last_name,
      countryCode: shippingAddress.country_code
    },
    helpText: '',
    preventCancel: false,
    isAddressEditable: false,
    isEditingPhoneNumber: false,
    forceEnableSubmit: true,
    touched: true,
    updating: isProcessingRepair,
    confirmButtonText: 'Update & Repair',
    canSaveAndContinue: false,
    cancel: onToggleModal,
    save: address => compareAndRepairAddress(address)
  };

  const errorCollapseAttribute = showFullError ? 'open' : 'closed';

  const isProcessingAction = isProcessingRepair || isProcessingRetry;

  return (
    <UiKit.GridRow templateColumns="1fr" gap="16" className="w-100">
      <Alert type="error">
        <div className={styles.errorWrapper}>
          <div className={styles.errorHeader}>
            <div className={styles.errorFriendlyText}>
              <If
                test={labelError.message}
                otherwise={'There was an error generating your shipping label'}
              >
                <span>{labelError.message}</span>
              </If>
            </div>
            <If test={labelError.error_trace}>
              <div className={styles.showFullErrorWrapper}>
                <span
                  role="button"
                  onClick={() => setShowFullError(show => !show)}
                  className={styles.showFullError}
                  data-collapse={errorCollapseAttribute}
                >
                  {showFullError ? 'Hide' : 'View'} Log
                  <Arrow className={styles.arrow} />
                </span>
              </div>
            </If>
          </div>
          <Collapse isOpen={showFullError}>
            <div className={styles.fullErrorWrapper}>
              <code className={styles.fullError}>{labelError.error_trace}</code>
            </div>
          </Collapse>
        </div>
      </Alert>
      <UiKit.GridRow gap="8">
        <UiKit.Button
          onClick={() => onRetryLabelGeneration()}
          disabled={isProcessingAction}
          processingText="Processing..."
          processing={isProcessingRetry}
          className={styles.button}
          wrapperClassName={styles.buttonContainer}
          customType="outline"
        >
          Retry Generation
        </UiKit.Button>
        <UiKit.Button
          onClick={onToggleModal}
          disabled={isProcessingAction}
          className={styles.button}
          wrapperClassName={styles.buttonContainer}
        >
          Repair & Retry
        </UiKit.Button>
      </UiKit.GridRow>
      <AddressEdit
        address={address}
        show={showModal}
        updating={address.updating}
        osData={orderService}
        formikProps={{ validateOnBlur: true, validateOnChange: false }}
        api={{ post, get, patch }}
      />
    </UiKit.GridRow>
  );
}
