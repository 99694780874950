import React from 'react';
import cn from 'classnames';
import styles from './index.module.scss';

import Modal, { DEFAULT_CONFIRM_FUNCTION } from '../Modal';

export default function SideSheet({
  size = 'xs', // ""xs", ""md", "lg"
  isOpen = false,
  title = '',
  footerInfo = '',
  onEnter = () => {},
  onExit = () => {},
  onOpened = () => {},
  onClose = () => {},
  onClosed = () => {},
  onConfirm = DEFAULT_CONFIRM_FUNCTION,
  isProcessing = false,
  isConfirmDisabled = false,
  hideCancelButton = false,
  confirmBtnType = undefined,
  confirmBtnText = undefined,
  confirmBtnProcessingText = undefined,
  cancelBtnText = undefined,
  confirmBtnClassName = '',
  cancelBtnClassName = '',
  bodyClassName = '',
  children = null,
  onBackBtnClick = () => {},
  ...reactstrapModalProps
}) {
  return (
    <Modal
      size={size}
      isOpen={isOpen}
      title={title}
      onEnter={onEnter}
      onOpened={onOpened}
      onClose={onClose}
      onClosed={onClosed}
      onExit={onExit}
      onConfirm={onConfirm}
      footerInfo={footerInfo}
      isCancelBtnHidden={hideCancelButton}
      confirmBtnType={confirmBtnType}
      confirmBtnText={confirmBtnText}
      confirmBtnProcessingText={confirmBtnProcessingText}
      cancelBtnText={cancelBtnText}
      isProcessing={isProcessing}
      isConfirmDisabled={isConfirmDisabled}
      className={styles.container}
      contentClassName={styles.content}
      headerClassName={styles.header}
      titleClassName={styles.title}
      footerClassName={styles.footer}
      footerInfoClassName={styles.footerText}
      confirmBtnClassName={confirmBtnClassName}
      cancelBtnClassName={cancelBtnClassName}
      modalTransition={{
        timeout: 300, // milliseconds
        appear: false,
        baseClass: styles.modal,
        baseClassActive: styles.show
      }}
      backdropTransition={{ timeout: 0 }}
      {...reactstrapModalProps}
    >
      <div className={cn(styles.body, bodyClassName)}>{children}</div>
    </Modal>
  );
}
