import useEcomPlatform from './useEcomPlatform';

export default function useSupportedProductAttributes() {
  const { checkIfPlatformIs } = useEcomPlatform();

  const isShopify = checkIfPlatformIs('SHOPIFY');
  const isWoo = checkIfPlatformIs('WOOCOMMERCE');

  return {
    supportsType: isShopify,
    supportsCategory: isWoo
    // supportsCollection: isShopify || isWoo
  };
}
