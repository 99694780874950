import React from 'react';
import styles from './index.module.scss';

import { PageErrorUi } from 'components';

export default function Error({
  isError = false,
  onReload = () => {},
  msg = 'Unable to load the data at the moment. Please try again later!',
  children = null
}) {
  const errorElement = !isError ? null : (
    <div className={styles.container}>
      <PageErrorUi
        isError
        hideImage
        msg={msg}
        onReload={onReload}
        wrapperClassName={styles.wrapper}
        contentClassName={styles.content}
        imageClassName={styles.image}
        reloadButtonType="primary"
      />
    </div>
  );

  return (
    <>
      {errorElement}
      {children}
    </>
  );
}
