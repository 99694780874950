import React, {
  useRef,
  useState,
  useCallback,
  useContext,
  useEffect,
  useMemo
} from 'react';
import { useHistory } from 'react-router-dom';
import { ShepherdTour, ShepherdTourContext } from 'react-shepherd';
import _get from 'lodash/get';
import _size from 'lodash/size';
import styles from './index.module.scss';

import { If, localStore } from 'utils';
import { useAccess } from 'utils/hooks';
import { AuthContext } from 'context/AuthContext';
import { Menu, TourTrigger } from './components';
import { getTourOptions } from 'containers/TourGuides/methods';
import { ProgressIndicator } from 'containers/TourGuides/components';
import { getTours } from './methods';
import { ReactComponent as CrossIcon } from 'assets/img/icons/close.svg';

const LOCAL_STORAGE_KEY = 'setupCheckListComplete';

const getVisitedToursMapFromLocalStorage = () => {
  const setupCheckListData = localStore.get(LOCAL_STORAGE_KEY);
  return setupCheckListData ? JSON.parse(setupCheckListData) : {};
};

const setVisitedToursMapToLocalStorage = newMap => {
  if (!newMap) return;
  localStore.put(LOCAL_STORAGE_KEY, JSON.stringify(newMap));
};

export default function FeaturesChecklist() {
  const { push } = useHistory();
  const welcomeTour = useContext(ShepherdTourContext);
  const [showMenu, setShowMenu] = useState(false);
  const [expanded, setExpanded] = useState(null);
  const [visitedToursMap, setVisitedToursMap] = useState(null);
  const { showSetupChecklist, onToggleSetupChecklist } = useContext(
    AuthContext
  );
  const { hasSubFeatureAccess } = useAccess();
  const initialRef = useRef(true);
  const toursData = useRef(getTours({ push, hasAccess: hasSubFeatureAccess }))
    .current;
  const toursList = useRef(
    toursData.reduce((acc, { tours }) => [...acc, ...tours], [])
  ).current;

  useEffect(() => {
    if (!showSetupChecklist) {
      setShowMenu(false);
    } else {
      setVisitedToursMap(getVisitedToursMapFromLocalStorage());
    }
  }, [showSetupChecklist]);

  useEffect(() => {
    const turnOffMenu = () => setShowMenu(false);
    welcomeTour.on('start', turnOffMenu);

    return () => welcomeTour.off('start', turnOffMenu);
  }, [welcomeTour]);

  useEffect(() => {
    if (visitedToursMap && initialRef.current) {
      initialRef.current = false;
      setExpanded(() => {
        const nextTour = toursList.find(tour => {
          const tourId = _get(tour, 'id');
          return !visitedToursMap[tourId];
        });
        return _get(nextTour, 'id', null);
      });
    }
  }, [visitedToursMap, toursList]);

  const onToggleSetup = e => {
    e.stopPropagation();
    onToggleSetupChecklist();
  };

  const onToggleMenu = useCallback(() => {
    setShowMenu(curr => !curr);
  }, []);

  const onTourStarted = useCallback(tourId => {
    if (!tourId) return;

    setShowMenu(curr => !curr);
    setVisitedToursMap(currMap => {
      const newVisitedTourMap = { ...currMap, [tourId]: true };
      setVisitedToursMapToLocalStorage(newVisitedTourMap);
      return newVisitedTourMap;
    });
  }, []);

  const onTourCancelled = useCallback(() => {
    setShowMenu(true);
  }, []);

  const onTourCompleted = useCallback(() => {
    const visitedToursMap = getVisitedToursMapFromLocalStorage();

    setShowMenu(true);
    setExpanded(currExpanded => {
      const nextTour = toursList.find(tour => {
        const tourId = _get(tour, 'id');
        return !visitedToursMap[tourId];
      });
      return _get(nextTour, 'id', currExpanded);
    });
  }, [toursList]);

  const tourItems = useMemo(() => {
    if (!showMenu) return null;

    return toursData.map(({ section, tours }) => {
      const displaySteps = tours.map(tour => {
        const tourOptions = getTourOptions({ tourName: tour.title });

        return (
          <ShepherdTour
            key={tour.title}
            steps={tour.steps}
            tourOptions={tourOptions}
          >
            <TourTrigger
              text={tour.title}
              description={tour.description}
              isExpanded={tour.id === expanded}
              isVisited={!!visitedToursMap[tour.id]}
              onStarted={() => onTourStarted(tour.id)}
              onCompleted={onTourCompleted}
              onCancelled={onTourCancelled}
              onExpand={() => setExpanded(tour.id)}
            />
          </ShepherdTour>
        );
      });

      return (
        <div className={styles.section} key={section}>
          <p className={styles.category}>{section}</p>
          <div className={styles.tours}>{displaySteps}</div>
        </div>
      );
    });
  }, [
    showMenu,
    expanded,
    toursData,
    visitedToursMap,
    onTourStarted,
    onTourCompleted,
    onTourCancelled
  ]);

  if (!showSetupChecklist) return null;

  return (
    <>
      <div className={styles.container} onClick={onToggleMenu}>
        <span className={styles.label}>Setup Checklist</span>
        <button className={styles.crossBtn} onClick={onToggleSetup}>
          <CrossIcon />
        </button>
      </div>
      <If test={showMenu}>
        <Menu onToggle={onToggleMenu}>
          <ProgressIndicator
            hideStepCount
            total={_size(toursList)}
            current={_size(visitedToursMap)}
          />
          <div className={styles.toursContainer}>{tourItems}</div>
        </Menu>
      </If>
    </>
  );
}
